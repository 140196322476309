<template>
	<div class="readme-article">
		<h1 id="高级功能">
			五、高级功能
		</h1>
		<h2 id="教案查询">1.教案查询</h2>
		<p>查看学生提交的教案记录，教室可查看和下载。</p>
		<p>
			<img src="@/assets/img/teach/5-1.png" />
		</p>
		<h2 id="课费升级">2.课费升级</h2>
		<p>查看操作日志列表。</p>
		<p>
			<img src="@/assets/img/teach/5-2.png" />
		</p>
	</div>
</template>

<script>
	export default {
		name: "Staff3-1",
		data() {
			return {};
		},
		mounted() {},
		methods: {},
	};
</script>
<style scoped>
</style>
